<template>
  <div>
    <div class="container w-full xl:w-6/12 mx-auto py-10 px-4 md:px-0">
      <ViewTitle :title="$t('help.title')" />
      <ViewSubtitle :subtitle="$t('help.subtitle')" />
      <div class="mt-4">
        <div
          class="mb-5 bg-gray-50 dark:bg-gray-800 p-5 rounded"
          v-for="faq in faqs"
          :key="faq.id"
        >
          <div>
            <h5 class="font-bold text-lg text-gray-700 dark:text-white mb-3">
              {{ $i18n.locale == "ru" ? faq.title : faq.title_en }}
            </h5>
          </div>

          <div>
            {{ $i18n.locale == "ru" ? faq.description : faq.description_en }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ViewSubtitle from "@/components/ViewSubtitle.vue";
import ViewTitle from "@/components/ViewTitle.vue";

export default {
  components: { ViewTitle, ViewSubtitle },
  data() {
    return {
      contentVisible: false,
    };
  },
  computed: mapState({
    faqs: (state) => state.faqs.list,
  }),
  created() {
    this.getFaqs();
  },
  watch: {
    $route: ["getFaqs"],
  },
  methods: {
    toggleAccordion: function (id) {
      if (this.contentVisible === id) {
        this.contentVisible = false;
      } else {
        this.contentVisible = id;
      }
    },
    getFaqs() {
      this.$store.dispatch("faqs/getFaqs");
    },
  },
};
</script>
